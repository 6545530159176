.__app-votescope__7ffa1 {
  position: relative;
}
.__app-votescope__7ffa1.pagebreak {
}
.__app-votescope__7ffa1 .location-bar {
  position: absolute;
  content: " ";
  width: calc(100% - 4px);
  left: 2px;
  top: 0px;
  border-top: 6px solid #3acce1;
  border-bottom: 8px solid transparent;
  border-radius: 20px;
  z-index: 1;
}

.__app-votescope__7ffa1 .votescopem-image {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.__app-votescope__7ffa1 .inner {
  @include card();
  padding: 0px;
  min-height: 150px;
  background: var(--primary-bg-color-3);
}

.__app-votescope__7ffa1 .header {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.__app-votescope__7ffa1 .body {
  padding: 10px;

  .meta {
    position: relative;
    font-size: 14px;
    padding: 0px 20px 0px 0px;
    font-weight: 300;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 30px;
    img {
      position: absolute;
      right: 0px;
      top: 6px;
      width: 16px;
    }
  }
}
.__app-votescope__7ffa1 .headline {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}
.__app-votescope__7ffa1 .body-text {
  font-size: 14px;
  font-weight: 300;
  color: var(--text-color-on-answer-field);
}
.__app-votescope__7ffa1 .add-agenda {
  position: absolute;
  right: 20px;
  top: calc(50% - 20px);
}
.__app-votescope__7ffa1 .add-agenda-text {
  position: absolute;
  font-size: 10px;
  text-align: center;
  width: 50px;
  right: -8px;
  top: 40px;
  color: var(--primary-bg-color);
}
.__app-votescope__7ffa1 .add-agenda-icon {
  width: 36px;
  height: 36px;
  background-color: var(--primary-bg-color);
  -webkit-mask-image: url("/icons/agenda-item.svg");
  mask-image: url("/icons/agenda-item.svg");
  transition: opacity 1s;
}

.__app-votescope__7ffa1.inactive .add-agenda-icon {
  opacity: 0;
}

.__app-votescope__7ffa1.active .add-agenda-icon {
  opacity: 1;
}

.__app-votescope__7ffa1 .checkbox {
  float: right;
}
.__app-votescope__7ffa1 .clearboth {
  clear: both;
}

.__app-votescope__7ffa1.active .inner {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
  background: #606060;
  color: white;
}

.__app-votescope__7ffa1.inactive .inner {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(-25%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(-25%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(0%);
  }
}
