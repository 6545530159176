.__image-modal__150a0 {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999999;
    background: rgba(#000000,0.8);
    .image-close {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 3rem;
        top: 2rem;
        padding: 0.5rem;
        background-color: black;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            width: 1rem;
            height: auto;
            padding: 0;
        }
    }
}
.__image-modal__150a0 img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    padding: 1rem;
    z-index: 9999999999999;
}