.__app-nav__f5f63 {
    position: absolute;
    right: 5px;
    top: 0px;
}

.__app-nav__f5f63 .nav-icon {
    width: 33px;
    position: absolute;
    right: 5px;
    z-index: 20;
    margin: 10px;
    .stripe {
        height: 0px;
        margin: 5px 0px;
        width: 100%;
        border-radius: 2px;
        border: 1px solid white;

        transition: width .5s, margin-left .5s;

        &:nth-child(2) {
         width: 66%;
         margin-left: 34%;
        }
        &:nth-child(3) {
         width: 50%;
         margin-left: 50%;
        }
        &:nth-child(4) {
         width: 33%;
         margin-left: 67%;
        }
    }
}

.__app-nav__f5f63.open .stripe{
    width: 100%!important;
    margin-left: 0%!important;
}
.__app-nav__f5f63 .btn-notification {
    transform: translate(-100%, -20%);
    right: auto;
}