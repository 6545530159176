.__app-header__a6d36 {
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    position: relative;
    background: var(--primary-bg-color-3);
    z-index: 300;
}
