$searchable-select-clear-bg: #33CBFA;
$searchable-select-clear-color: #fff;
$searchable-select-add-bg: #e2e2e2;
$searchable-select-item-bg--selected: #eef6fa;
$searchable-select-item-bg--hover: #f6f6f6;
$searchable-select-no-results-color: #9a9a9a;
$searchable-select-spacer-horizontal--has-icon: 2.15em;
$searchable-select-spacer-horizontal: 1em;

.Searchable-select {
  position: relative;
  font-size: 14px;

  li {
    list-style: none;
    line-height: 1.2;
    padding: 0.6em $searchable-select-spacer-horizontal--has-icon;
  }

  &__label {
    display: block;
    width: 100%;
    padding: 0.58em $searchable-select-spacer-horizontal;
    border: 1px solid #ccc;
    text-decoration: none;
    color: inherit;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAXBAMAAADq5DJkAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAYUExURQAAADIyMiYmJjIyMjAwMDMzMzExMczMzG/7/KwAAAAHdFJOUwCoC4YjllkQbp1YAAAARklEQVQY02MoYEAB9OYyGTswMDgbwbgsgiEMLIEicFlDUQdXQbgsg7JgSKCwApzLZCgIlESYrCwIlERwmQyNUOxVUaCjBwG62A+AiSlt5gAAAABJRU5ErkJggg==") right 15px center no-repeat;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &--menu-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--multiple {
      background: none;
      padding-top: 0.7em;
      min-height: 41/14*1em;
    }

    &--multiple-selected {
      padding: 6/14*1em 4/14*1em 0;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: inherit;
    }

    &:focus {
      border-color: #66afe9;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    }
  }

  &__selected-pill {
    display: inline-block;
    padding: 0 4px 0 8px;
    margin-left: 2px;
    margin-bottom: 6px;
    vertical-align: middle;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    font-size: 13/14*1em;
    line-height: 25/13*1em;
    background: #f5f5f5;
    background: lighten($searchable-select-item-bg--selected, 1);
    cursor: default;
  }

  &__selected-pill-clear {
    display: inline-block;
    color: #bbb;
    padding: 0 2px;
    color: #888;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1.1em;

    &:hover {
      color: #333;
    }
  }

  &__search {
    padding: 0.7em;
    background: #f6f6f6;
  }

  &__input {
    display: block;
    width: 100%;
    padding: 0.6em 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      border-color: #66afe9;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    }
  }

  &__clear {
    position: relative;
    line-height: 1;
    padding: 0.75em $searchable-select-spacer-horizontal--has-icon;
    cursor: pointer;
    background: $searchable-select-clear-bg;
    color: $searchable-select-clear-color;

    &:hover {
      background: darken($searchable-select-clear-bg, 5);
    }

    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  &__info {
    padding: 0.8em $searchable-select-spacer-horizontal;
    color: $searchable-select-no-results-color;
  }

  &__add-new {
    position: relative;
    padding: 0.8em $searchable-select-spacer-horizontal;
    line-height: 1;
    background:$searchable-select-add-bg;
    cursor: pointer;

    &:hover {
      background: darken($searchable-select-add-bg, 5);
    }

    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  &__loader {
    vertical-align: middle;
    width: 0.857em;
  }

  &__loader-text {
    vertical-align: middle;
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 20;
    width: 100%;
    background: #fff;
    border: 1px solid #D7D7D7;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
  }

  &__options-list {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px 0;
    margin: 0;
  }

  &__option {
    cursor: pointer;
    position: relative;

    &--selected{background: $searchable-select-item-bg--selected;}

    &:hover,
    &:focus {
      background: $searchable-select-item-bg--hover;
      outline: none;
    }

    &--disabled {
      color: #8C8C8C;
      cursor: not-allowed;
    }
  }

  &__icon {
    position: absolute;
    left: 0.6em;
    top: 50%;
    transform: translateY(-0.6em);

    &__check {
      color: #8c8c8c;
    }

    &--clear {
      font-size: 1.3em;
      font-weight: bold;
    }
  }

  &__matched-text {
    font-weight: bold;
  }
}
