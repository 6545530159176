
@keyframes fadein {
    from { opacity: 0; transform: translateY(10px); }
    to   { opacity: 1; transform: translateY(0px);}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; transform: translateY(10px);}
    to   { opacity: 1; transform: translateY(0px); }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; transform: translateY(10px);}
    to   { opacity: 1; transform: translateY(0px); }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; transform: translateY(10px);}
    to   { opacity: 1; transform: translateY(0px); }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; transform: translateY(10px);}
    to   { opacity: 1; transform: translateY(0px); }
}
@mixin fade($property) {
    -webkit-animation: fadein $property; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein $property; /* Firefox < 16 */
     -ms-animation: fadein $property; /* Internet Explorer */
      -o-animation: fadein $property; /* Opera < 12.1 */
         animation: fadein $property;
}

@mixin shadow() {
    box-shadow: 0px 0px 20px -12px black;
}

@mixin card() {
    border-radius: 10px;
    background: white;
     @include shadow();
    padding: 20px 20px 10px;
    margin: 0 0 10px 0;
    position: relative;
}

.card {
     @include card();
}

.fade-in {
    //@include fade(1s); 
}
